<template>
  <div style="background: #fff;height: 100%;
">
    <van-nav-bar
      style="background: #f5f5f5;"
      title="代朋友办理"
      left-arrow
      :fixed="true"
      :z-index="999"
      @click-left="back"
    ></van-nav-bar>
    <div style="overflow: hidden;background: #f5f5f5;padding-top:45px;height: 100%;">
      <div class="hed">
        <div class="car-number">
          <van-row class="user_links2" style="border-bottom: 1px solid #dcdcdc;">
            <van-col span="5" class="chepeiib">车牌号码:</van-col>
            <van-col span="16" style="float: right">
              <div class="item vux-1px-l" v-for="(v, i) in carNumberArr" :key="i">
                <div class="flex">{{carNumber[i] || ''}}</div>
              </div>
            </van-col>
          </van-row>
        </div>
        <van-row class="user_links" style="border-bottom: 1px solid #dcdcdc;">
          <van-col span="5" class="chepeii">手机号码:</van-col>
          <van-col span="18">
            <van-field
              v-model="addForm.phone"
              input-align="right"
              readonly
              type="tel"
              placeholder="请输入手机号码"
            />
          </van-col>
        </van-row>
        <div class="qy_code_wrapper" v-if="ermisok">
          <vue-qr
            :text="link"
            :margin="10"
            colorDark="#000"
            colorLight="#fff"
            :dotScale="1"
            :logoSrc="img"
            :logoScale="0.2"
            :size="300"
          ></vue-qr>
        </div>
        <div class="renzhen">
          <div class="renzhen_a">
            <div class="renzhen_h1">办理成功！</div>
          </div>
          <div class="renzhen_b">
            <div class="hh2">
              <van-button plain class="right" type="default" size="small" @click="copy">复制凭证码</van-button>
            </div>

            <div class="hh3">
              <div class="left pzma">核销凭证码</div>
              <div class="left" style="width: 90%;">
                <div class="cc_ma">凭证码：{{pinzhenma.orderNumber}}</div>
                <div class="qy_code_wrapper">
                  <vue-qr
                    :text="'http://customer.car-posthouse.cn/matching/verification.html?orderNumber='+pinzhenma.orderNumber"
                    :margin="10"
                    colorDark="#000"
                    colorLight="#fff"
                    :dotScale="1"
                    logoSrc
                    :logoScale="0.2"
                    :size="160"
                  ></vue-qr>
                </div>
                <div class="tishi">请出示此二维码到门店前台核销</div>
              </div>
            </div>
          </div>
        </div>
        <div class="wxts">
          <p>温馨提示：</p>
          <p>关闭此页面后，客户的核销二维码可以在个人数据统计—未核销客户——点击"代办"查看.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import utils from "../../assets/utils";
import vueQr from "vue-qr";
export default {
  components: {
    vueQr
  },
  data() {
    return {
      gid: "",
      addForm: {},
      carNumberArr: ["", "", "", "", "", "", "", ""],
      carNumber: [],
      showKeyBoard: false,
      ermisok: false,
      pinzhenma: {},
      type: "0",
      aid: this.$route.query.aid,
      carNo: this.$route.query.carNo,
      link: this.$route.query.link,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      img: require("../../assets/img/yicheju.png"),
      uid: "",
      list: {}
    };
  },
  methods: {},
  created() {
    this.pinzhen();
    this.getConfig()
  },
  methods: {
    copy() {
      const input = document.createElement("input");
      document.body.appendChild(input);
      var http = this.pinzhenma.orderNumber;
      input.setAttribute("value", http);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        this.$toast.success("复制成功！");
      }
      document.body.removeChild(input);
    },
    //是否购买
    pinzhen() {
      userModel
        .goumaicarNo({
          aid: this.aid,
          carNo: this.carNo
          //   carNo: '粤NNNNNN'
          // aid: 18
        })
        .then(res => {
          console.log(res.data);
          // window.clearInterval(this.times);
          if (res.code == 200) {
            sessionStorage.setItem("vxMange", res.data.vxMange);
            if (res.data.carNo == null) {
              console.log("没用买过");
              this.pinzhenisok = true;
            } else {
              console.log("买过了");
              this.pinzhenma = res.data;
              this.addForm.phone = res.data.phone;
              this.carNumber = res.data.carNo.split("");
              this.pinzhenisok = false;
            }
            // this.listfenxiamg = res.data;
          }
        });
    },
        getConfig() {
          let that = this;
      let userAgent = navigator.userAgent
      let shareUrl ='';
      if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
          shareUrl = sessionStorage.getItem('originUrl')
      }else {
          shareUrl = location.href
      }
      userModel
        .config({
          url: shareUrl,
          gid: 208
        })
        .then(res => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: "wx406b62706ded5aa8", // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: [
                "openLocation",
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ] // 必填，需要使用的JS接口列表
            });
              var link =
                "https://marketing.car-posthouse.cn/commission_xq?aid=" +
                that.aid +
                "&carNo=" +
                that.pinzhenma.carNo ;
            var desc =
              that.userInfo.realName + "帮您代办活动二维码！";
            var Img = require("../../assets/img/toubei.png"); //实际自己的图片路径地址
            var url = window.location.href;
            var hosts = url.split("index.html")[0]; //获取域名加打包文件夹名称
            var imgUrl =
              "https://marketing.car-posthouse.cn/assets/img/fen_img.d2df3ae1.png";
            //分享给朋友
            wx.ready(() => {
              wx.updateAppMessageShareData({
                title: that.list.shareTitle,
                desc: desc,
                link: link,
                imgUrl: that.list.shareImg,
                success: function() {
                  that.$toast.success("分享成功！");
                }
              });
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    },
    back() {
      this.$router.go(-1);
    }
  }
};
</script>
<style  lang="less" scoped>
#app {
  background: #f5f5f5;
  height: 100%;
}
.hed {
  //   background: #fff;
}
// --------------
.car-number {
  height: 50px;
}
.item {
  border: 1px solid #013bba;
  border-radius: 2px;
  width: 24px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  float: left;
  margin-right: 5px;
}
.item:last-child {
  background: #4b84ff;
  border: 1px solid #013bba;
}
#key-board {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.gzname {
  font-size: 12px;
  background: #fff;
  display: inline-block;
  padding: 1px 12px;
  color: #013bb9;
  border-radius: 6px;
  margin-bottom: 12px;
}
.gzname_a {
  font-size: 12px;
  color: #fff;
  margin-top: 10px;
}
.chepeiib {
  line-height: 34px;
  font-weight: bold;
  font-family: PingFangSC-Medium;
}
.user_links2 {
  padding: 9px 0;
  padding-left: 5px;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  background: #fff;
}
.user_links {
  background: #fff;
  padding: 12px 0;
  padding-left: 5px;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  border-bottom: 1px solid #dcdcdc;
}
.chepeii {
  line-height: 22px;
  font-weight: bold;
  font-family: PingFangSC-Medium;
}
.van-cell {
  padding: 0;
  // background-color: #f1f1f1;
}
// ----------------------------
.renzhen {
  color: #000;
  // padding-left: 8px;
  // padding-top: 15px;
  // height: 100px;
  // background: #f5f5f5;
  overflow: hidden;
  // margin-top: 5px;
  margin-bottom: 10px;
  padding-bottom: 5px;
}
.renzhen_a {
  margin: 5px 10px 0px 10px;
  border-radius: 10px;
  // margin-top: 5px;
  background: #fff;
  height: 70px;
  overflow: hidden;
}
.renzhen_h1 {
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  margin-right: 3px;
  color: red;
}
.renzhen_b {
  background: #fff;
  height: 320px;
  margin: 0px 10px;
  border-radius: 10px;
  border-top: 1px dashed #f5f5f5;
  overflow: hidden;
  padding-bottom: 10px;
}
.hh2 {
  padding: 10px 12px;
  font-weight: bold;
  font-size: 14px;
  margin-top: 2px;
  line-height: 30px;
  overflow: hidden;
}
.cc_ma {
  padding: 8px 0;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}
.pzma {
  width: 26px;
  line-height: 16px;
  padding: 10px 6px;
  text-align: center;
  background: #333;
  color: #fff;
  height: 100%;
  font-size: 13px;
  border-radius: 5px 0 0 5px;
}
.hh3 {
  height: 80%;
  margin: 0 12px;
  background: #f9f9f9;
}
.qy_code_wrapper {
  margin-left: 12px;
  width: 150px;
  margin: auto;
}
.qy_code_wrapper img {
  display: block;
  // text-align: center;
}
.tishi {
  font-size: 12px;
  // margin-left: 12px;
  color: #666;
  font-weight: bold;
  text-align: center;
}
.wxts {
  padding: 10px 50px;
  font-size: 12px;
}
.wxts p {
  line-height: 18px;
}
</style>